import "./styles.scss"

import React from "react"
import { motion } from "framer-motion"

import fadeAnimation from "utils/fadeAnimation"

const Content = ({ pageData }) => {
  return (
    <section className="shipping-content">
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-xl-10">
            <motion.div {...fadeAnimation}>
              <h4>{pageData?.acfShipping?.contentHeader}</h4>
              <div
                dangerouslySetInnerHTML={{
                  __html: pageData?.acfShipping?.contentMain,
                }}
              />
            </motion.div>

            {pageData?.acfShipping?.shippingTypes?.map((item, index) => (
              <motion.div key={index} {...fadeAnimation}>
                <div className="shipping-content__divider" />
                <div className="row align-items-center">
                  <div className="col-md-7">
                    <h3>{item?.header}</h3>
                    <div
                      dangerouslySetInnerHTML={{ __html: item?.description }}
                    />
                  </div>
                  <div className="col-md-4 offset-md-1">
                    {index === 0 ? (
                      <div className="shipping-content__logo">
                        <img src={item?.image?.localFile?.publicURL} alt="" />
                      </div>
                    ) : (
                      <div className="shipping-content__image">
                        <img src={item?.image?.localFile?.publicURL} alt="" />
                      </div>
                    )}
                  </div>
                </div>
              </motion.div>
            ))}

            <div className="shipping-content__divider" />

            <div className="row align-items-center">
              <motion.div className="col-md-11" {...fadeAnimation}>
                <h3>{pageData?.acfShipping?.bottomHeader}</h3>
                <div
                  dangerouslySetInnerHTML={{
                    __html: pageData?.acfShipping?.bottomDescription,
                  }}
                />
              </motion.div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Content
